import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import { Link, ModalRoutingContext } from 'gatsby-plugin-modal-routing-3'

import Header from "../components/header";

const TestimonialPage = ({ pageContext, data }) => {
	
	const { testimonial } = pageContext
	
	const { next, previous } = pageContext
	const nextArticle = next && (
    <Link to={"/"+next.frontmatter.slug} asModal
		state={{
			noScroll: true
		}}>
      <strong>Næsta</strong> <br/>
      {next.frontmatter.name}
    </Link>
  )

  const prevArticle = previous && (
    <Link to={"/"+previous.frontmatter.slug} asModal
		state={{
			noScroll: true
		}}
		>
      <strong>Fyrri</strong> <br/>
      {previous.frontmatter.name}
    </Link>
  )

	return (
  <ModalRoutingContext.Consumer>

			{({ modal, closeTo }) => (
				<div id="main" className="wrapper">
					{modal ? (
						<Link to={closeTo} className="absolute top-4 right-4 md:top-8 md:right-8 z-50 text-retulBlue">
							<span className="sr-only">Loka</span>
							<svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
						</Link>
					) : (
						<Header siteTitle={data.site.siteMetadata.title} />
					)}
				


					<div className="modal-container h-screen w-screen overflow-hidden">
						<div className="modal-content overflow-scroll h-screen pb-20 md:grid md:grid-cols-12">

						<div className="image md:col-span-6 mx-auto p-8 pt-16 md:p-16 md:self-center">
							<GatsbyImage image={data.markdownRemark.frontmatter.photo?.childImageSharp.gatsbyImageData} className="testimonial__photo" alt={data.markdownRemark.frontmatter.name} />
						</div>

						<div className="text md:col-span-6 pb-6 md:py-16 px-8 md:px-16 md:self-center">
							<p className="text-retulBlue text-8xl leading-3 transform -translate-y-8 -translate-x-2">„</p>
							<div dangerouslySetInnerHTML={{__html: data.markdownRemark.html}} className="text-lg max-w-prose"></div>
							<p className="text-gray-200 text-right text-lg max-w-prose">
								— {data.markdownRemark.frontmatter.name}
								{ testimonial.frontmatter.title ? <span className="block" dangerouslySetInnerHTML={{__html: '<em>'+data.markdownRemark.frontmatter.title+'</em>' }} ></span> : '' }
							</p>
						</div>

						</div>

					<div className="nav-container fixed h-20 inset-x-0 bottom-0 bg-black flex flex-row text-xs md:text-sm">
						<div className="w-1/2  p-6 text-right">
							{prevArticle}
						</div>
						<div className="w-1/2  p-6 text-left">
							{nextArticle}
						</div>
					</div>

					</div>
					


					</div>
			)}
  </ModalRoutingContext.Consumer>
);};

export default TestimonialPage;


export const testimonialQuery = graphql`
	query($slug: String!) {
		site {
			siteMetadata {
				title
			}
		}
		markdownRemark(frontmatter: { slug: { eq: $slug } }) {
		frontmatter {
			name
			photo {
				relativePath
				childImageSharp {
					gatsbyImageData(layout:CONSTRAINED)
				}
				}
			title
			excerpt
			slug
		}
		html
		}
	}
`;